.imageContainer {
    display: flex;
    flex-direction: 'column';
    justify-content: 'center';
    align-items: 'center';
    /* border: '1px solid #ccc'; */
    /* background-color: '#fff';
    color: '#000'; */
    /* fontFamily: '"Arial", sans-serif';
    fontSize: '20px';
    textAlign: 'center'; */
}

@media (min-width: 600px) {
    .imageContainer,.flirty-mainBackground  {
        width: 400px; /* Width for larger screens */
        height: 500px; /* Maintain 4:5 aspect ratio */
    }
}

@media (max-width: 599px) {
    .imageContainer, .flirty-mainBackground  {
        width: 200px; /* Smaller width for smaller screens */
        height: 250px; /* Maintain 4:5 aspect ratio */
    }
}


/* Original Styles */
.original-mainBackground {
    background-color: #FFFFFF;
    /* White background */
    color: #000000;
    /* Black text */
    border: 15px solid #FFD700;
    /* Gold border */
    font-family: 'Playfair Display', serif;
    font-size: calc(10px + 2vmin);
    text-align: center;
    width: 1024px;
    height: 1024px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.original-footer {
    margin-top: auto;
    padding: 10px;
    background-color: #333333;
    /* Dark footer */
    color: #FFFFFF;
    /* White text */
    width: 100%;
    text-align: center;
}

/* New Flirty Styles */
.flirty-mainBackground {
    background-color: #FED4C4;
    /* Soft Peach */
    color: #C71585;
    /* Deep Rose text */
    border: 3px double #FFD700;
    /* Gold border */
    font-family: 'Playfair Display', serif;
    font-size: calc(10px + 2vmin);
    text-align: center;


    flex-direction: column;
    justify-content: center;
    align-items: center;


    /* position: 'relative'; */


   
}

.flirty-footer {
    background-color: #FED4C4;
    /* Soft Peach */

    color: #C71585;
    /* Deep Rose text */

    margin-top: auto; /* Pushes the footer to the bottom of the flex container */
    width: 100%; /* Ensures the footer spans the full width of the container */
    text-align: left; /* Aligns text to the left */
    padding: 10px; /* Adds padding inside the footer */
    width: 100%;
    font-family: "Arial, sans-serif";
    font-size: 16px;
    
   
}

/* New Flirty Styles with Background Image */
.flirty2-mainBackground {
    background-image: url('https://img.freepik.com/free-photo/natures-beauty-captured-colorful-flower-close-up-generative-ai_188544-8593.jpg');
    background-position: center; /* Adjust this to focus on a specific part of the image */
    background-size: cover; /* Covers the entire background; use 'contain' if you need to see the whole image */
    background-repeat: no-repeat;
    background-color: #FED4C4; /* Fallback color */
    color: #C71585; /* Deep Rose text */
    border: 3px double #FFD700; /* Gold border */
    font-family: 'Playfair Display', serif;
    font-size: calc(10px + 3vmin);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px; /* Set a fixed height or adjust as needed */
    width: 400px; /* Set a fixed width or adjust as needed */
    position: relative;
}

/* Text with readable background rectangle */
.flirty2-text {
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white for readability */
    padding: 10px;
    border-radius: 10px; /* Optional, for rounded corners */
    width: calc(100% - 20px); /* Adjust width as needed, accounting for padding */
}

.flirty2-footer {
    background-color: rgba(255, 255, 255, 0.5); /* Soft Peach */
    color: #C71585; /* Deep Rose text */
    padding: 10px;
    width: 100%; /* Ensures the footer spans the full width of the container */
    text-align: left; /* Aligns text to the left */
    font-family: "Roboto, sans-serif";
    font-size: 16px;
    position: absolute;
    bottom: 0; /* Anchors the footer at the bottom */
    left: 0; /* Anchors the footer at the left side */
}
